import React from 'react'
import styled from 'styled-components'

const WhiteButton = styled.button`
    box-sizing: border-box;
    display: block;
    width: 20%;
    min-width: 200px;
    height: 50px;
    line-height: 45px;
    margin: 0 auto;
    border: 1px solid var(--white);
    font-family: var(--main-font-light);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-button);
    text-align: center;
    font-size: auto;
    background: var(--white);

    &:hover{
        //ADD ANIMATIONS
        cursor: pointer;
    }
`


const BlackButton = styled.button`
    box-sizing: border-box;
    display: block;
    width: 20%;
    min-width: 200px;
    height: 50px;
    line-height: 45px;
    margin: 0 auto;
    border: 1px solid var(--white);
    font-family: var(--main-font-light);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-button);
    text-align: center;
    font-size: auto;
    background: var(--black);
    color: var(--white);

    &:hover{
        //ADD ANIMATIONS
        cursor: pointer;
    }
`

const ContactButton = ({ title, color="white", type=""}) => {
        if(color === "white"){
            return(
                <WhiteButton type={type}>
                    <h5>{title}</h5>
                </WhiteButton>
            )
        }else{
            return(
                <BlackButton type={type}>
                    <h5>{title}</h5>
                </BlackButton>
            )
        }
}

export default ContactButton